import { Link, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import * as React from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Badge from '@mui/material/Badge';

// Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


// Icônes dossier Menu/icons
// import calendarBike from './icons/calendar_bike.png' //Calendrier coloré non retenu
import { Avatar } from '../../Users/Trombinoscope/Effectifs.js'
import { store } from "../../Redux/Store.js"
import { getTrombinesSeason } from '../../Redux/AvatarsSlice.js'
import { getUserConnected, getNNewNotifs } from '../../Redux/UserConnectedSlice.js'
import { DicoItemsMenu } from './DicoItemsMenu.js';

import useWindowDimensions from './useWindowDimensions.js'
import Banner from './Banner.js'
import './Menu.css'


// Item avec Link, pouvant être item principal ou dans une sous-liste
function LinkItem(props) {

    const onClick = () => {
        if (props.menuSmall) {
            props.onClose()
        }
    }

    const nNewNotifs = useSelector(state => getNNewNotifs(state))
    let dicoItem = DicoItemsMenu[props.item]

    // console.log(dicoItem)
    return (
        <Link onClick={onClick} key={props.item} to={dicoItem['link']} style={{ color: 'inherit', textDecoration: 'inherit' }} >
            <ListItemButton sx={{ p: props.padding, "&:hover": { backgroundColor: "#326dae" } }} >
                <ListItemIcon style={{ minWidth: '30px' }} >

                    {(dicoItem['type'] === 'img') ?
                        <img src={dicoItem['icon']} className='icon_menu' alt='item' />
                        : dicoItem['icon']
                    }

                </ListItemIcon>
                {((props.item === "Mes Notifications") && (! [0, '0', undefined].includes(nNewNotifs))) ?
                    <Badge badgeContent={nNewNotifs.toString()} color="#fe6038" sx={{ color: 'white', "& .MuiBadge-badge": { backgroundColor: "#fe6038" } }}>
                        <ListItemText primary={props.item} />
                    </Badge>
                    : <ListItemText primary={props.item} />
                }

            </ListItemButton>
        </Link>
    )
}


// Groupe d'Items (ex "Mes Informations") avec possibilité de dérouler le groupe
function GroupItems(props) {
    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen(!open)
    }

    return (
        <List sx={{ p: 0 }} >
            <ListItemButton sx={{ p: '10px', "&:hover": { backgroundColor: "#326dae" } }} onClick={handleClick}>
                <ListItemIcon style={{ minWidth: '30px' }}> {DicoItemsMenu[props.item]['icon']} </ListItemIcon>
                <ListItemText primary={props.item} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" style={{ padding: '0' }} >
                    {props.subItems.map(subItem =>
                        <LinkItem item={subItem} key={subItem} padding='6px 6px 6px 30px' onClose={props.onClose} menuSmall={props.menuSmall} />
                    )}
                </List>
            </Collapse>
        </List>
    )
}


// Composant avec paramètre props.items pouvant être :
// --> un GroupItems si props.items.subItems existe (ex props.items = { 'item': "Mes Informations", 'subItems': ['Mon profil', 'Mes notifications', 'Changer mot de passe'] })
// --> sinon un item tout seul avec link (ex props.items = { 'item': 'Accueil' })

function MenuGroup(props) {
    // console.log(props.items.hasOwnProperty('subItems'))
    return (
        <div>
            {(props.items.hasOwnProperty('subItems'))
                ? <GroupItems item={props.items.item} subItems={props.items.subItems} onClose={props.onClose} menuSmall={props.menuSmall} />
                : <LinkItem item={props.items.item} padding='10px' onClose={props.onClose} menuSmall={props.menuSmall} />
            }
        </div>
    )
}


// Largeur du Menu
const drawerWidth = 220

// Permet de wrapper le composant principal dynamiquement pour :
// --> Ajouter ou retirer une marge en cas d'affichage du SideMenu
// --> Faire les transitions propres
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        // flexBasis: '50%',
        // flexShrink: 0.5,
        // overflowX: 'auto',

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
)



// Au final pour la Bar et le DrawerHeade je n'utilise pas de wrapping avec styled
// Mais je laisse le code au cas où pour l'exemple

// const AppBar = styled(MuiAppBar, {
//     shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//     transition: theme.transitions.create(['margin', 'width'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...(open && {
//         width: `calc(100% - ${drawerWidth}px)`,
//         marginLeft: `${drawerWidth}px`,
//         transition: theme.transitions.create(['margin', 'width'], {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//     }),
// }));


// const DrawerHeader = styled('div')(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//     justifyContent: 'flex-end',
// }))






// Fonction qui retourne la valeur d'un booléen stockée dans le localStorage
// La fonction retourne true si l'item n'est pas dans le localStorage
// Utilisée pour rembember ou openMenu

function getStorageOpenMenu() {
    let item = sessionStorage.getItem('openMenu')

    // Si l'item n'est pas dans le localStorage il est true par défaut
    if (item === null) {
        item = true
    } else {
        item = JSON.parse(item)
    }
    return item
}


// Le paramètre menuSmall sert à indiquer qu'il s'agit du petit Menu, ouvert  dans ce cas on déclenche
//
export function SideMenu(props) {
    const menu = (props.userConnected.menu === undefined) ? [] : props.userConnected.menu

    return (
        <List>
            {menu.map((items, index) =>
                <MenuGroup items={items} key={index} onClose={props.onClose} menuSmall={props.menuSmall} />
            )}
        </List>
    )
}



export default function MenuBarMarge({ children }) {
    const location = useLocation()
    // console.log(location)
    const { width } = useWindowDimensions()
    const [open, setOpen] = useState(getStorageOpenMenu())

    useEffect(() => {
        setOpenMenu(width > 1000)
    }, [width])


    const setOpenMenu = (value) => {
        sessionStorage.setItem("openMenu", value)
        // console.log(value)
        setOpen(value)
    }

    let state = store.getState()
    const userConnected = getUserConnected(state)

    // Trombines pour les coachs
    let trombines = getTrombinesSeason(state)

    // Style dynamique pour la largeur de la main_page
    // Je mets 99.1 et non 100 car les scrollbar sous google Chrome sont plus larges
    // D'ailleurs il faudrait que je vois comment les avoir moins larges...
    let style = (open) ? { width: `calc(100vw - ${drawerWidth}px)` }
        : { width: '100vw' }

    return (
        (['/', '/login'].includes(location.pathname))
            ? null
            :
            <Box className='page_menu' >
                <Drawer sx={{
                    width: drawerWidth,
                    '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#00499a', color: 'white', scrollbarWidth: 'thin' },
                }}
                    variant="persistent"
                    anchor="left"
                    open={open} >

                    <div className='drawer_header'>

                        <div className='avatar_connected' >
                            <Avatar avatar={trombines?.[userConnected.user]} user={userConnected.user} style={{ borderRadius: '30px' }} link='mon_profil' />
                        </div>
                        <IconButton onClick={() => setOpenMenu(false)} sx={{ color: 'white', mr: '5px', "&:hover": { backgroundColor: "#326dae" } }} >
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <SideMenu userConnected={userConnected} setOpen={setOpen} />
                </Drawer>

                <Main open={open}>
                    <div style={style} >
                        <Banner open={open} setOpen={setOpenMenu} coureurs_coach={userConnected.coureurs_coach} />
                        <div className='main_page'>
                            {children}
                        </div>
                    </div>
                </Main>
            </Box>
    )
}
