// import ListItem from '@mui/material/ListItem';
// import SvgIcon from '@mui/material/SvgIcon';

// Icons
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import AppsIcon from '@mui/icons-material/Apps';
import LockResetIcon from '@mui/icons-material/LockReset';
import InfoIcon from '@mui/icons-material/Info';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ChatIcon from '@mui/icons-material/Chat';
import EmailIcon from '@mui/icons-material/Email';
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import SettingsIcon from '@mui/icons-material/Settings';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import LoginIcon from '@mui/icons-material/Login';
import CalculateIcon from '@mui/icons-material/Calculate';
// import LinkIcon from '@mui/icons-material/Link';
import BallotIcon from '@mui/icons-material/Ballot';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PortraitIcon from '@mui/icons-material/Portrait';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import BalanceIcon from '@mui/icons-material/Balance';
import RestaurantIcon from '@mui/icons-material/Restaurant';
// Icônes applis
import ciseaux from '../../Apps/home/icons/ciseaux_small.png'
import bilans from '../../Apps/home/icons/bilans.png'
import puce from '../../Apps/home/icons/puce_small.jpg'
import tensiometre from '../../Apps/home/icons/tensiometre_small.png'
import PPR from '../../Apps/home/icons/PPR_small.png'
// import performance from '../../Apps/home/icons/performance.png'
import eclair from '../../Apps/home/icons/eclair.jpg'
// import materiel from '../../Apps/home/icons/material.png'
import carte from '../../Apps/home/icons/carte.jpeg'
import cerveau from '../../Apps/home/icons/cerveau.jpeg'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
// Icônes dossier Menu/icons
// import calendarBike from './icons/calendar_bike.png' //Calendrier coloré non retenu
import zonesFlag from './icons/LGBT_Rainbow_Flag.png'
import garminLogo from './icons/garmin2.png'
import SetMealIcon from '@mui/icons-material/SetMeal';

import {CURRENT_SEASON} from './../../Divers/Const'

import './Menu.css'

// Exemple liste de tous les groupes d'item pour le Menu
// Liste de tous les groupes d'item pour le Menu
// Les données seront envoyées depuis le backend en fonction du rôle de l'utilisateur
// const MenuGroupsExemple = [
//     { 'item': 'Accueil' },
//     { 'item': "L'équipe" },
//     { 'item': "Mes Informations", 'subItems': ['Mon Profil', 'Mes Notifications', 'Changer mot de passe', 'Connecter compte Garmin'] },
//     // { 'item': "Calendriers", 'subItems': ['Mon calendrier', 'Calendrier de l\'équipe', 'Calendrier des Coureurs'] },
//     { 'item': "Mon Calendrier" },
//     { 'item': 'Calendriers des Coureurs' },
//     { 'item': 'Mon Suivi d\'Activités' },
//     { 'item': 'Suivi d\'Activités', 'subItems': ['Dernières Activités', 'Par coureur', 'Par course'] }, //, 'Chargement des Activités'
//     { 'item': 'Entrainement', 'subItems': ['Découpage Séances', 'Historique Séances', 'Bilans Activités'] },
//     { 'item': 'Matériel Coureurs' },
//     { 'item': 'Gestions des coureurs', 'subItems': ['Zones d\'intensité'] },
//     {
//         'item': 'Applications', 'subItems': ['Découpages', 'Bilans', 'Historique', 'Glycémie', 'Tensions Rayons', 'PPR/PTR', 'Testing Performance', 'Matériel',
//             'Résumé Activité', 'Tests Cognitifs']
//     },
//     {
//         'item': 'Admin', 'subItems': ['Ajout Utilisateur', 'Gestion Utilisateurs', 'Gestion Droits', 'Coureurs Entraineurs', 'Participation Courses']
//     },

//     // { 'item': 'Vélos', 'subItems': ['Matériel Coureurs', 'Gestion Matériel'] },
//     // { 'item': 'Statistiques' },
//     // { 'item': 'Documents', 'subItems': ['Liste des documents', 'Ajouter un document'] },
//     // { 'item': 'Véhicules', 'subItems': ['Liste des véhicules', 'Gestion des véhicules'] },
//     // { 'item': 'News', 'subItems': ['Liste des News', 'Ajouter une news'] },
// ]


// Dico qui à chaque item du sideMenu associe une icône, qui peut être :
//  - une icône mui
//  - une image png ou jpeg
export const DicoItemsMenu = {
    'Accueil': { 'link': 'accueil', 'icon': <HomeIcon className='icon_item' /> },

    'L\'équipe': { 'link': 'effectif', 'icon': <GroupIcon className='icon_item' /> },

    'Mes Informations': { 'link': '', 'icon': <InfoIcon className='icon_item' /> },
    'Mon Profil': { 'link': 'mon_profil', 'icon': <AccountBoxIcon className='icon_item' /> },
    'Mes Notifications': { 'link': 'notifications', 'icon': <NotificationsIcon className='icon_item' /> },
    'Changer Mot De Passe': { 'link': 'change_password', 'icon': <LockResetIcon className='icon_item' /> },

    'Mes Paramètres Coureur': { 'link': '', 'icon': <SettingsIcon className='icon_item' /> },
    'Synchronisation Garmin': { 'link': 'connect_garmin', 'icon': garminLogo, 'type': 'img' },
    "Mes zones d'intensité": { 'link': 'mes_zones', 'icon': zonesFlag, 'type': 'img' },
    'Suivi Poids': { 'link': 'mon_suivi_poids', 'icon': <BalanceIcon className='icon_item' /> },


    'Calendriers': { 'link': '', 'icon': <CalendarMonthIcon className='icon_item' /> },
    "Calendrier de l'équipe": { 'link': 'calendrier_equipe', 'icon': <CalendarMonthIcon className='icon_item' /> },

    "Mon Calendrier": { 'link': 'mon_calendrier', 'icon': <CalendarMonthIcon className='icon_item' /> },
    'Calendriers Coureurs': { 'link': 'planif_coureurs', 'icon': <CalendarMonthIcon className='icon_item' /> },
    'Calendrier Double': { 'link': 'calendrier_double', 'icon': <ImportContactsIcon className='icon_item' /> },
    
    'Feedbacks': { 'link': '', 'icon': <QuestionAnswerIcon className='icon_item' /> },
    'Coureur': { 'link': 'feedbacks', 'icon': <DirectionsBikeIcon className='icon_item' /> },
    'Équipe': { 'link': 'feedbacks/team', 'icon': <GroupIcon className='icon_item' /> },

    'Activités': { 'link': '', 'icon': <InsightsIcon /> },
    'Charger mes Activités': { 'link': 'charger_mes_activites', 'icon': <CloudDownloadIcon className='icon_item' /> },
    'Mon Suivi d\'Activités': { 'link': 'mon_suivi', 'icon': <ChatIcon className='icon_item' /> },
    'News': { 'link': 'liste_news', 'icon': <EmailIcon className='icon_item' /> },

    'Suivi d\'Activités': { 'link': '', 'icon': <ChatIcon className='icon_item' /> },
    'Mes Coureurs': { 'link': 'activites_mes_coureurs', 'icon': <ChatIcon className='icon_item' /> },
    'Par Coureur': { 'link': 'activites_coureurs', 'icon': <ChatIcon className='icon_item' /> },
    'Activités World Tour': { 'link': 'suivi_activites_team/World Tour', 'icon': <ChatIcon className='icon_item' /> },
    'Activités Conti': { 'link': 'suivi_activites_team/Conti', 'icon': <ChatIcon className='icon_item' /> },
    'Activités Juniors': { 'link': 'suivi_activites_team/Juniors', 'icon': <ChatIcon className='icon_item' /> },
    // 'Par Course': { 'link': 'activites_coureurs', 'icon': <ChatIcon className='icon_item' /> },
    // 'Chargement des Activités': { 'link': 'charger_activites', 'icon': <CloudDownloadIcon className='icon_item' /> },

    'Entrainement': { 'link': '', 'icon': <ElectricBikeIcon className='icon_item' /> },
    'Découpage Séances': { 'link': 'cuts_coureurs', 'icon': ciseaux, 'type': 'img' },
    'Historique Séances': { 'link': 'historique_coureurs', 'icon': eclair, 'type': 'img' },
    'Bilans Activités': { 'link': 'bilans_coureurs', 'icon': bilans, 'type': 'img' },

    'Matériel': { 'link': '', 'icon': <DirectionsBikeIcon className='icon_item' /> },
    'Matériel Coureurs': { 'link': 'materiel_coureurs', 'icon': <DirectionsBikeIcon className='icon_item' /> },
    'Gestion Matériel': { 'link': 'gestion_materiel', 'icon': <BallotIcon className='icon_item' /> },

    'Suivi Médical' : {'link' : '', 'icon' : <MonitorHeartIcon className='icon_item' />},
    'Monitoring Glycémie' : {'link' : 'glycemie_coureurs', 'icon' : <RestaurantIcon className='icon_item' />},
    'Complémentations' : {'link' : 'complementation_coureurs', 'icon' : <SetMealIcon className='icon_item' />},


    'Testing' : {'link' : 'testing', 'icon' : <AnalyticsIcon className='icon_item' />},

    'Gestions des Coureurs': { 'link': '', 'icon': <ManageAccountsIcon className='icon_item' /> },
    "Zones d'intensité": { 'link': 'zones_coureurs', 'icon': zonesFlag, 'type': 'img' },
    'Masses Corporelles': { 'link': 'masses_coureurs', 'icon': <BalanceIcon className='icon_item' /> },
    'Synchronisations Garmin': { 'link': 'sync_garmins_coureurs', 'icon': garminLogo, 'type': 'img' },
    'Export FIT': { 'link': 'export_fit_coureurs', 'icon': <CloudDownloadIcon className='icon_item' /> },
    'Calculs Coureurs': { 'link': 'calculs_coureur', 'icon': <CalculateIcon className='icon_item' /> },

    'Applications': { 'link': 'home', 'icon': <AppsIcon className='icon_item' /> },
    'Découpages': { 'link': 'cuts', 'icon': ciseaux, 'type': 'img' },
    'Bilans': { 'link': 'bilans', 'icon': bilans, 'type': 'img' },
    'Historique': { 'link': 'history', 'icon': eclair, 'type': 'img' },
    'Notice': { 'link': 'notice', 'icon': <InfoIcon className='icon_item' /> },
    'Glycémie': { 'link': 'glycemie', 'icon': puce, 'type': 'img' },
    'Tensions Rayons': { 'link': 'tensions', 'icon': tensiometre, 'type': 'img' },
    'PPR/PTR': { 'link': 'ppr', 'icon': PPR, 'type': 'img' },
    // 'Testing Performance': { 'link': 'performance', 'icon': performance, 'type': 'img' },
    //'Matériel': { 'link': 'materiel', 'icon': materiel, 'type': 'img' },
    'Résumé Activité': { 'link': 'activityRecap', 'icon': carte, 'type': 'img' },
    'Tests Cognitifs': { 'link': 'AppPVTTest', 'icon': cerveau, 'type': 'img' },

    'Gestion Utilisateurs': { 'link': 'gestion_users', 'icon': <ManageAccountsIcon className='icon_item' /> },
    'Ajout Utilisateur': { 'link': 'ajout_utilisateur', 'icon': <PersonAddAltIcon className='icon_item' /> },
    'Modification Utilisateurs': { 'link': 'update_utilisateurs', 'icon': <ManageAccountsIcon className='icon_item' /> },
    'Effectifs Saisons': { 'link': `effectif_saisons/${CURRENT_SEASON}`, 'icon': <GroupIcon className='icon_item' /> },
    'Avatars': { 'link': `effectif_update_avatar/${CURRENT_SEASON}`, 'icon': <PortraitIcon className='icon_item' /> },
    'Activation/Désactivation Comptes': { 'link': 'modify_accounts', 'icon': <PersonAddAlt1Icon className='icon_item' /> },
    'Suppression Comptes': { 'link': 'delete_accounts', 'icon': <PersonOffIcon className='icon_item' /> },
    'Gestion Droits': { 'link': 'gestion_droits', 'icon': <NotificationsIcon className='icon_item' /> },


    'Admin': { 'link': '', 'icon': <EngineeringIcon className='icon_item' /> },
    'Tests Rôles': { 'link': 'login_admin', 'icon': <LoginIcon className='icon_item' /> },
    'Notifications': { 'link': 'notifications_users', 'icon': <NotificationsIcon className='icon_item' /> },
    'Coureurs ⇄ Entraineurs': { 'link': 'coureurs_entraineurs', 'icon': <TransferWithinAStationIcon className='icon_item' /> },
    'Participation Courses': { 'link': 'participation', 'icon': <AdminPanelSettingsIcon className='icon_item' /> },

    'Se Déconnecter': { 'link': 'logout', 'icon': <ExitToAppOutlinedIcon className='icon_item' /> },

    // 'Vélos': { 'link': '', 'icon': <DirectionsBikeIcon /> },
    // 'Matériel Coureurs': { 'link': 'materiel_coureur', 'icon': <StarBorder /> },
    // 'Gestion Matériel': { 'link': 'gestion_materiel', 'icon': <StarBorder /> },

    // 'Documents': { 'link': '', 'icon': <FolderOpenIcon /> },
    // 'Liste des documents': { 'link': 'list_documents', 'icon': <FolderOpenIcon /> },
    // 'Ajouter un document': { 'link': 'ajout_document', 'icon': <FolderOpenIcon /> },

    // 'News': { 'link': '', 'icon': <CommentIcon /> },
    // 'Liste des News': { 'link': 'list_news', 'icon': <CommentIcon /> },
    // 'Ajouter une news': { 'link': 'editor', 'icon': <CommentIcon /> },

    // 'Statistiques': { 'link': 'statistiques', 'icon': <QueryStatsIcon /> },

    // 'Véhicules': { 'link': '', 'icon': <AirportShuttleIcon /> },
    // 'Liste des véhicules': { 'link': 'vehicules', 'icon': <AirportShuttleIcon /> },
    // 'Gestion des véhicules': { 'link': 'gestion_vehicules', 'icon': <AirportShuttleIcon /> },
}

